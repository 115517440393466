import { Box, Button, Divider } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import style from './accordionContainer.m.less'

type AccordionContainerState = 'opened' | 'closed'

interface IAccordionContainer {
    openButtonTitle: string
    title: string
    uneditable: boolean
    isOpen: boolean
    onDelete?: () => void
    onOpen?: () => void
    name: string
}

export const AccordionContainer: FC<IAccordionContainer> = ({
    children,
    openButtonTitle,
    title,
    uneditable,
    isOpen,
    onDelete,
    onOpen,
}) => {
    const [state, setState] = useState<AccordionContainerState>(() =>
        isOpen ? 'opened' : 'closed',
    )

    if (state === 'closed' && !uneditable) {
        return (
            <Box direction="row" align="center">
                <Button
                    type="link"
                    onClick={() => {
                        setState('opened')
                        onOpen && onOpen()
                    }}
                >
                    {openButtonTitle}
                </Button>
            </Box>
        )
    }

    if (!uneditable) {
        return (
            <Box>
                <Box direction="row" align="center">
                    {title}
                    <Button
                        type="link"
                        onClick={() => {
                            setState('closed')
                            onDelete && onDelete()
                        }}
                    >
                        Удалить
                    </Button>
                    <Divider className={style.divider} style={{ width: '100%' }} />
                </Box>
                {children}
            </Box>
        )
    }

    return (
        <Box>
            <Box direction="row" align="center">
                {title}
                <Divider className={style.divider} style={{ width: '100%' }} />
            </Box>
            {children}
        </Box>
    )
}
