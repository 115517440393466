// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".priceContainer__okZI {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.priceTitle_QpzYe {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.priceValue_wqdD0 {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 140px;\n  height: 24px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  text-align: right;\n  color: #303440;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OwnershipTransfer/components/DfaAndPriceOffer/dfaAndPriceOffer.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AAAJ;AAGA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AADJ","sourcesContent":[".priceContainer {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.priceTitle {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.priceValue {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    width: 140px;\n    height: 24px;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    text-align: right;\n    color: #303440;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceContainer": "priceContainer__okZI",
	"priceTitle": "priceTitle_QpzYe",
	"priceValue": "priceValue_wqdD0"
};
export default ___CSS_LOADER_EXPORT___;
