import { IDfa, IssueAvailableInvestorsType } from '@dltru/dfa-models'
import { DataProviderResponse, WrappedAutocomplete } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import api from '@services/api'

export interface IDfaOption extends IDfa {
    label: string
    value: string
}

interface IDfasAutocomplete {
    isEdit: boolean
    name: string | string[]
    onSelect: (dfa: IDfaOption) => void
    onlyUnqual?: boolean
}

export const DfasAutocomplete: FC<IDfasAutocomplete> = ({ isEdit, name, onSelect, onlyUnqual }) => {
    const [selected, setSelected] = useState<DataProviderResponse[]>([])

    const onSelectHandler = (selectedOptions: DataProviderResponse[]) => {
        const options = selectedOptions as unknown as IDfaOption[]
        onSelect(options[0])

        setSelected(selectedOptions)
    }

    const getData = async (value: unknown) => {
        try {
            if (!value) {
                return []
            }

            const { data } = await api.lib.getDfaList({
                ticker_symbol: `like(${value})`,
                status: ['emitment_success'],
            })
            const items = onlyUnqual
                ? data?.items?.filter(
                      (item) =>
                          item.decision.issue_available_investors_type !==
                          IssueAvailableInvestorsType.qualified,
                  )
                : data?.items

            return (
                items?.map((item) => ({
                    ...item,
                    label: item.ticker_symbol,
                    value: item.ticker_symbol,
                })) ?? []
            )
        } catch (error) {
            return []
        }
    }

    return (
        <WrappedAutocomplete
            className="formComponentItem"
            dataProvider={getData}
            onSelect={onSelectHandler}
            defaultOptions={selected}
            name={name}
            label="Актив"
            selectProps={{
                size: 'large',
                placeholder: 'Укажите тикер выпуска',
            }}
            required
            uneditable={isEdit}
        />
    )
}
