import React from 'react'

export const transferApproveAgreementText = (
    <>
        <p>Настоящая Оферта направляется в соответствии с пунктом 12.32 Правил.</p>
        <p>
            Оферта считается направленной на условиях, указанных Пользователем в форме «Условия
            оферты» и документе, приложенном Пользователем к такой форме. В случае если имеются
            противоречия между сведениями, указанными Пользователем в форме «Условия оферты», и
            информацией, содержащейся в документе, приложенном Пользователем к такой форме, при
            определении условий Оферты приоритет имеют сведения, указанные в форме «Условия оферты»,
            в том числе в целях исполнения Оператором требований, установленных законодательством
            Российской Федерации.
        </p>
        <p>Оферта считается направленной с момента нажатия кнопки «Да, направить оферту».</p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)

export const transferAcceptAgreementText = (
    <>
        <p>
            Настоящий Акцепт направляется в соответствии с пунктом 12.33 Правил в целях принятия
            Оферты, условия которой указаны в форме «Условия оферты» и документе, приложенном к
            такой форме.
        </p>
        <p>
            В случае если имеются противоречия между сведениями, указанными в форме «Условия
            оферты», и информацией, содержащейся в документе, приложенном к такой форме, при
            определении условий Оферты приоритет имеют сведения, указанные в форме «Условия оферты»,
            в том числе в целях исполнения Оператором требований, установленных законодательством
            Российской Федерации.
        </p>
        <p>Акцепт считается направленным с момента нажатия кнопки «Да, принять оферту».</p>
        <p>
            Все термины, встречающиеся в настоящем тексте с заглавной буквы, применяются в значении,
            предусмотренном правилами информационной системы Общества с ограниченной
            ответственностью «Системы распределенного реестра».
        </p>
    </>
)
