import { BaseDescriptionEnum, IDfa, ProfileType } from '@dltru/dfa-models'
import {
    Box,
    CurrencyInput,
    Divider,
    IntegerInput,
    MoneyOut,
    WrappedInput,
    dfaValidators,
} from '@dltru/dfa-ui'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { AccordionContainer } from '../AccordionContainer/AccordionContainer'
import { DfasAutocomplete, IDfaOption } from '../DfasAutocomplete/DfasAutocomplete'
import { DfasInfoCard } from '../DfasInfoCard/DfasInfoCard'
import style from './dfaAndPriceOffer.m.less'

interface IDfaAndPriceOffer {
    isEdit: boolean
    currentClientType: ProfileType
    currentBaseDescription: BaseDescriptionEnum
    type: 'acceptor' | 'offerent'
    isOfferOpen: { dfa: boolean; money: boolean }
    isOnlyDfa: boolean
    onDfaChange: (dfa?: IDfa) => void
    onOpen: (val: { dfa: boolean; money: boolean }) => void
    onClose: (val: 'money' | 'dfa') => void
}

export const DfaAndPriceOffer: FC<IDfaAndPriceOffer> = ({
    isEdit,
    type = 'offerent',
    isOfferOpen,
    isOnlyDfa,
    onOpen,
    onClose,
    onDfaChange,
}) => {
    const [selectedDfa, setSelectedDfa] = useState<IDfaOption>()
    const qualifyInfo = useSelector(profileSelector.selectQualifyInfo)
    // костыль
    const currentOffer = useSelector((state) => dfaTransferSelector.selectOfferByType(state, type))
    const { validateFields, isFieldTouched } = useContext(FieldContext)

    const onSelectHandler = (selectedOption: IDfaOption) => {
        onDfaChange(selectedOption)
        setSelectedDfa(selectedOption)
        if (isFieldTouched([type, 'amount_dfa'])) {
            validateFields([[type, 'amount_dfa']])
        }
    }

    let priceView = null
    if (isEdit && currentOffer?.price && !isOnlyDfa) {
        priceView = (
            <Box
                padding={[0, 24, 24, 24]}
                margin={[0, 0, 8, 0]}
                className="tablebox tablebox--auto-height"
            >
                <div style={{ marginTop: 16 }} className={style.priceContainer}>
                    <div className={style.priceTitle}>
                        <MoneyOut />
                        <span style={{ marginLeft: 10 }}>{'Денежные средства'}</span>
                    </div>
                    <div className={style.priceValue}>
                        <span>{currentOffer?.price} ₽</span>
                    </div>
                </div>
            </Box>
        )
    }

    if (!isEdit && !isOnlyDfa) {
        priceView = (
            <AccordionContainer
                openButtonTitle="Добавить денежные средства"
                title="Денежные средства"
                uneditable={isEdit}
                isOpen={isOfferOpen?.money}
                onOpen={() => {
                    onOpen({ ...isOfferOpen, money: true })
                }}
                onDelete={() => {
                    onClose('money')
                    onOpen({ ...isOfferOpen, money: false })
                }}
                name="money"
            >
                <div className="formRow">
                    <CurrencyInput
                        className="formComponentItem formComponentItem--md"
                        label="Денежные средства"
                        name={[type, 'total_price']}
                        placeholder="Укажите количество денежных средств"
                        required
                        uneditable={isEdit}
                        maxLength={18}
                        /*rules={[
                            {
                                validator: moneyValidators.maxSum(maxTotalPrice),
                            },
                        ]}*/
                        //disabled={currentBaseDescription === BaseDescriptionEnum.donation_contract}
                    />
                </div>
            </AccordionContainer>
        )
    }

    let dfaView = null
    if (isEdit && selectedDfa?.value) {
        dfaView = (
            <DfasInfoCard dfaId={selectedDfa.id} value={currentOffer?.amount_dfa} type={type} />
        )
    }

    const onlyUnqual = type === 'acceptor' && qualifyInfo?.is_qualified === false
    if (!isEdit) {
        dfaView = (
            <>
                <AccordionContainer
                    openButtonTitle="Добавить ЦФА"
                    title="ЦФА"
                    uneditable={isEdit}
                    isOpen={isOfferOpen?.dfa}
                    onOpen={() => {
                        onOpen({ ...isOfferOpen, dfa: true })
                    }}
                    onDelete={() => {
                        onClose('dfa')
                        setSelectedDfa(undefined)
                        onOpen({ ...isOfferOpen, dfa: false })
                    }}
                    name="dfa"
                >
                    <div className="formRow">
                        <DfasAutocomplete
                            name={[type, 'dfa']}
                            isEdit={isEdit}
                            onSelect={onSelectHandler}
                            onlyUnqual={onlyUnqual}
                        />
                    </div>
                    <div className="formRow">
                        <WrappedInput
                            name={[type, 'dfa_name']}
                            required
                            disabled
                            label="Наименование"
                            className="formComponentItem"
                            uneditable={isEdit}
                            //renderUneditable={isEdit}
                        />
                    </div>
                    <div className="formRow">
                        <IntegerInput
                            className="formComponentItem formComponentItem--md"
                            label="Количество"
                            name={[type, 'amount_dfa']}
                            placeholder="Укажите количества ЦФА в единицах"
                            required
                            rules={[
                                {
                                    validator: dfaValidators.amount(
                                        selectedDfa?.total_supply_invested,
                                    ),
                                },
                            ]}
                            maxLength={19}
                            uneditable={isEdit}
                        />
                    </div>
                </AccordionContainer>
                <Divider margin={[8, 0, 32, 0]} />
            </>
        )
    }

    return (
        <>
            {dfaView}
            {priceView}
        </>
    )
}
